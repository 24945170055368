* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Courier New", Courier, monospace;
  background: black;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #eee;
}

input,
button,
label {
  font-family: "Courier New", Courier, monospace;
}

html,
body,
input,
button,
label {
  color: #eee;
}

button {
  cursor: pointer;
}

label {
  margin-bottom: 0.25rem;
  user-select: none;
}

sup {
  vertical-align: super;
  font-size: 0.75rem;
}

canvas {
  touch-action: none;
}

p {
  margin: 1rem 0 0 0;
  line-height: 1.4;
}

h3 {
  margin: 1rem 0;
  padding: 0;
  font-weight: bold;
}

a {
  color: rgb(0, 144, 255);
}

a:visited {
  color: rgba(0, 144, 255, 0.5);
}

strong {
  font-weight: bold;
}
